/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react'

import ErrorBoundary from 'components/ErrorBoundary'
import { UserProvider } from 'contexts/User'

const wrapRootElement = ({ element }) => (
  <ErrorBoundary>
    <UserProvider>
      {element}
    </UserProvider>
  </ErrorBoundary>
)

export {
  wrapRootElement,
}