import * as React from 'react'
import PropTypes from 'prop-types'

import './Spinner.scss'

const Spinner = (props) => (
  <div
    className={`container ${props.inlineStyle ? 'inlineStyle' : ''}`}
    style={{ width: props.size, height: props.size }}>
    <div className="loader" style={{ width: props.size, height: props.size }}>
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  </div>
)

export default Spinner

Spinner.propTypes = {
  inlineStyle: PropTypes.bool,
  size: PropTypes.number,
}

Spinner.defaultProps = {
  size: 16,
  inlineStyle: false,
}
