// @ts-ignore
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './Heading.module.scss'

export default function Heading({
  children,
  align = null,
  className = null,
  element = null,
  flexApart = null,
  flush = null,
  level = '2',
  levelStyle = null,
  ...props
}) {
  let Component = 'h2'

  if (level === '1') {
    Component = 'h1'
  } else if (level === '3') {
    Component = 'h3'
  } else if (level === '4') {
    Component = 'h4'
  } else if (level === '5') {
    Component = 'h5'
  }

  if (element === 'legend') {
    Component = 'legend'
  }

  return (
    <Component
      {...props}
      className={classNames(
        'Heading',
        align && styles[`this---${align}`],
        flexApart && styles['this---flexApart'],
        flush && styles['this---flush'],
        !levelStyle && level && styles[`this---level---${level}`],
        levelStyle && styles[`this---level---${levelStyle}`],
        className
      )}>
      {children}
    </Component>
  )
}

Heading.propTypes = {
  align: PropTypes.oneOf(['left', 'center']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  element: PropTypes.oneOf(['legend']),
  flexApart: PropTypes.bool,
  flush: PropTypes.bool,
  level: PropTypes.oneOf(['1', '2', '3', '4', '5']),
  levelStyle: PropTypes.oneOf(['1', '2', '3', '4', '5']),
}
