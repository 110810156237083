import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import LinkBase from '@fundrocket/common-web/components/LinkBase'
import MediaObject from '@fundrocket/common-web/components/MediaObject'
import Spinner from '@fundrocket/common-web/components/Spinner'

import styles from './Button.module.scss'

export default function Button({
  children,
  className = null,
  color = null,
  disabled = null,
  disabledVisible = true,
  //event = null,
  faux = null,
  full = null,
  hover = null,
  icon = null,
  iconAppend = null,
  inlineStyle = true,
  level = 'secondary',
  loading = null,
  onClick = null,
  shape = null,
  size = 'medium',
  to = null,
  type = 'button',
  unstyled = null,
  ...others
}) {
  const Component = faux ? 'span' : 'button'

  const componentClassNames = classNames(
    'Button',
    !disabledVisible && styles['this---disabled'],
    !unstyled && styles.this,
    !unstyled && color && styles[`this---${color}`],
    !unstyled && full && styles['this---full'],
    !unstyled && hover && styles['this---hover'],
    !unstyled && level && styles[`this---${level}`],
    !unstyled && shape && styles[`this---${shape}`],
    !unstyled && size && styles[`this---${size}`],
    className
  )

  let body = children

  if (icon) {
    body = iconAppend ? (
      <MediaObject center figure="right" size="small">
        <span>{children}</span>
        {icon}
      </MediaObject>
    ) : (
      <MediaObject center size="small">
        {icon}
        <span>{children}</span>
      </MediaObject>
    )
  }

  if (loading) {
    body = (
      <>
        <span>{body}</span>
        <Spinner size="16px" inlineStyle={inlineStyle} />
      </>
    )
  }

  function handleClick(e) {
    if (onClick) onClick(e)
  }

  const componentProps = {
    disabled: loading || disabled,
    onClick: handleClick,
  }

  if (to) {
    return (
      <LinkBase {...others} {...componentProps} to={to} className={componentClassNames}>
        {body}
      </LinkBase>
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <Component
      {...others}
      {...componentProps}
      type={faux ? null : type}
      className={componentClassNames}>
      {body}
    </Component>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['red', 'blue', 'white']),
  disabled: PropTypes.bool,
  disabledVisible: PropTypes.bool,
  event: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  faux: PropTypes.bool,
  full: PropTypes.bool,
  hover: PropTypes.bool,
  icon: PropTypes.node,
  iconAppend: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  level: PropTypes.oneOf(['primary', 'secondary', 'text', 'icon']),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  shape: PropTypes.oneOf(['circle']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  unstyled: PropTypes.bool,
}
