import classNames from 'classnames'
import { isString } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import Image from '@fundrocket/common-web/components/Image'

import Button from '@fundrocket/common-web/components/Button'
import Container from '@fundrocket/common-web/components/Container'
import Heading from '@fundrocket/common-web/components/Heading'
import Row from '@fundrocket/common-web/components/Row'
import Text from '@fundrocket/common-web/components/Text'

import styles from './Module1.module.scss'

export default function Module1({
  align,
  button,
  buttonTo,
  children,
  className,
  image,
  testid,
  title,
}) {
  return (
    <Text
      align={align}
      element="section"
      className={classNames('Module1', styles.this, className)}
      testid={testid}>
      {image && (
        <Row size="xsmall">
          <Container size="small">
            <Image src={image} className={styles.image} />
          </Container>
        </Row>
      )}
      <Heading
        level="1"
        //levelStyle="2"
        className={styles.title}>
        <Text size="xxxlarge">{title}</Text>
      </Heading>
      {isString(children) ? <p>{children}</p> : children}
      {buttonTo ? <Button to={buttonTo}>{button}</Button> : button}
    </Text>
  )
}

Module1.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  button: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]),
  buttonTo: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  image: PropTypes.string,
  testid: PropTypes.string,
  title: PropTypes.string.isRequired,
}

Module1.defaultProps = {
  align: 'center',
}
