import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './MediaObject.module.scss'

function wrapChildren(firstChild, lastChild, figure) {
  const wrappedFirstChild = (
    <div className={styles[figure === 'left' ? 'figure' : 'body']}>{firstChild}</div>
  )

  const wrappedLastChild = (
    <div className={styles[figure === 'left' ? 'body' : 'figure']}>{lastChild}</div>
  )

  return [wrappedFirstChild, wrappedLastChild]
}

export default function MediaObject({
  center,
  children,
  className,
  figure,
  inline,
  reset,
  size,
  split,
}) {
  const [firstChild, lastChild] = children
  const [leftChild, rightChild] = wrapChildren(firstChild, lastChild, figure)

  return (
    <div
      className={classNames(
        'MediaObject',
        styles.this,
        center && styles['this---center'],
        inline && styles['this---inline'],
        reset && styles[`this---reset---${reset}`],
        size && styles[`this---${size}`],
        split && styles['this---split'],
        className
      )}>
      {leftChild}
      {rightChild}
    </div>
  )
}

MediaObject.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  figure: PropTypes.oneOf(['left', 'right']),
  inline: PropTypes.bool,
  reset: PropTypes.oneOf(['desktop---large', 'tablet', 'mobile']),
  size: PropTypes.oneOf(['large', 'medium', 'small', 'xsmall']),
  split: PropTypes.bool,
}

MediaObject.defaultProps = {
  figure: 'left',
  size: 'medium',
}
