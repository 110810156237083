import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { isEmail, isMobilePhone } from 'validator'

export default function LinkBase({ children, className, event, target, to, ...others }) {
  const componentClassNames = classNames('LinkBase', className)
  let toIsEmail
  let toIsPhone

  if (to) {
    toIsEmail = isEmail(to)
    toIsPhone = isMobilePhone(to)
  }

  if (!to || toIsEmail || toIsPhone || to.includes('http')) {
    let body = children
    let href = to || null

    if (toIsEmail || toIsPhone) {
      body = children || to.replace('mailto:', '').replace('tel:', '')
    }

    if (toIsEmail) href = `mailto:${to}`

    if (toIsPhone) {
      href = `tel:${to.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')}`
    }

    return (
      <a
        {...others}
        href={href}
        target={target ? target : to ? '_blank' : null}
        className={componentClassNames}>
        {body}
      </a>
    )
  }

  return (
    <GatsbyLink {...others} to={to} className={componentClassNames}>
      {children}
    </GatsbyLink>
  )
}

LinkBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  event: PropTypes.bool,
  target: PropTypes.oneOf(['_blank', '_self']),
  to: PropTypes.string,
}
