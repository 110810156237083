import LocalStorage from '@fundrocket/common/libs/data-store'

const mixpanelStore = new LocalStorage('mixpanel')

const MixpanelStorage = {
  get: (prop = 'events') => mixpanelStore.get(prop),
  push: (...data) => {
    const eventsArray = [...(mixpanelStore.get('events') || []), ...data]
    mixpanelStore.set('events', eventsArray)
  },
  clear: () => mixpanelStore.clear(),
}

export default MixpanelStorage
