import * as Sentry from '@sentry/browser'
import Mixpanel from 'mixpanel-browser'

import EventTracker from '..'

const { GATSBY_FB_APP_ID } = process.env
const { GATSBY_FB_PIXEL_ID } = process.env
const { GATSBY_FR_RELEASE_LEVEL } = process.env
const { GATSBY_GTM_ID } = process.env
const { GATSBY_MIXPANEL_TOKEN } = process.env
const { GATSBY_SENTRY_DSN } = process.env
const { GATSBY_SEGMENT_API_KEY } = process.env
const { GATSBY_FR_DEBUG } = process.env

class EventTrackerWeb extends EventTracker {
  initAndConfigureIntegrations() {
    const { DEBUG, ENV, FB_APP, FB_PIXEL, GTM, MIXPANEL, SEGMENT, SENTRY, isLocal } = this.envKeys

    this.mapTrackEventIntegrations = this.getIntegrationsMap()

    if (isLocal) {
      return this
    }

    if (SENTRY) {
      this.Sentry = Sentry
      this.Sentry.init({
        environment: ENV,
        dsn: SENTRY,
      })
    }

    if (MIXPANEL) {
      this.Mixpanel = Mixpanel
      this.Mixpanel.init(MIXPANEL)
      this.Mixpanel.set_config({ debug: ENV !== 'prod' })
    }

    if (SEGMENT && !DEBUG) {
      this.Segment = true
    }

    if (GTM) {
      this.Ga = typeof window.ga === 'function' && window.ga
    }

    if (FB_PIXEL && FB_APP) {
      this.Fb = typeof window.fbq === 'function' && window.fbq
      this.Fb('init', FB_PIXEL)
      this.Fb('set', 'mobileBridge', FB_PIXEL, FB_APP)
    }

    return this
  }
}

const reporter = new EventTrackerWeb({
  DEBUG: GATSBY_FR_DEBUG,
  ENV: GATSBY_FR_RELEASE_LEVEL,
  FB_APP: GATSBY_FB_APP_ID,
  FB_PIXEL: GATSBY_FB_PIXEL_ID,
  GTM: GATSBY_GTM_ID,
  MIXPANEL: GATSBY_MIXPANEL_TOKEN,
  SEGMENT: GATSBY_SEGMENT_API_KEY,
  SENTRY: GATSBY_SENTRY_DSN,
})

reporter.initAndConfigureIntegrations()

export default reporter
