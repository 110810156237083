class LocalStorage {
  constructor(prefix) {
    this.prefix = prefix
  }

  set(prop, value) {
    localStorage.setItem(`${this.prefix}.${prop}`, JSON.stringify(value))
    return JSON.stringify(value)
  }

  get(prop) {
    return JSON.parse(localStorage.getItem(`${this.prefix}.${prop}`))
  }

  clear(all = false) {
    if (all) {
      localStorage.clear()
      return null
    }
    for (const key in localStorage) {
      if (key.includes(this.prefix)) localStorage.removeItem(key)
    }

    return localStorage
  }
}

export default LocalStorage
