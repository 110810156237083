module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://pay.womply.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://01b4c8efcb8148349cd4fa36ee88b7ad@o132238.ingest.sentry.io/5304187","enabled":true,"environment":"stg","release":"fundrocket-web@a8993929fc5b0de90c01d9e875bc3608c49b844e","normalizeDepth":6},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
