const APP = '/app/'

export default {
  HOME: '/',
  FORBIDDEN: '/forbidden/',
  SIGN_IN: '/sign-in/',
  ERROR_NOT_FOUND: '/404/',
  ERROR_SERVER: '/500/',

  APP,
  BANK_ACCOUNT: `${APP}bank-account/`,
  BANK_LINK: `${APP}bank-link/`,
  BANK_RELINK: `${APP}bank-relink/`,
  BUSINESS_TAX_ID: `${APP}business-tax-id/`,
  DASHBOARD: `${APP}dashboard/`,
  DOCUMENTS: `${APP}documents/`,
  SETUP: `${APP}setup/`,
  TERMS: `${APP}terms/`,

  SETUP_TERMS: '/20201005-setup-terms.md',
}
