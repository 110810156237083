import classNames from 'classnames'
import _ from 'lodash'
import GatsbyImage from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './Image.module.scss'

export default function Image({ align, alt, className, full, shadow, src, src2x, ...props }) {
  if (!src && !src2x) return null

  const componentClassNames = classNames(
    'Image',
    shadow && styles[`this---shadow---${shadow}`],
    className
  )

  if (_.isObject(src)) {
    return (
      <GatsbyImage
        //fluid={src.childImageSharp.fluid}
        fluid={src}
        className={componentClassNames}
      />
    )
  }

  return (
    <img
      {...props}
      alt={alt}
      src={src}
      srcSet={src2x && `${src2x} 2x`}
      className={classNames(
        componentClassNames,
        align && styles[`this---${align}`],
        full && styles['this---full']
      )}
      aria-hidden={alt ? null : true}
    />
  )
}

Image.propTypes = {
  align: PropTypes.oneOf(['center']),
  alt: PropTypes.string,
  className: PropTypes.string,
  full: PropTypes.bool,
  shadow: PropTypes.oneOf(['red']),
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  src2x: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

Image.defaultProps = {
  alt: '',
}
