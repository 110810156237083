import MixpanelStorage from '@fundrocket/common/libs/data-store/mixpanel'

const stubFn = () => {}

const delayExecution = (callback) => setTimeout(callback, 0)

class EventTracking {
  constructor({ ...keys }) {
    this.Mixpanel = null
    this.Ga = null
    this.Fb = null
    this.Segment = null
    this.Sentry = null

    this.envKeys = {
      ...keys,
      isLocal: keys.ENV === 'local',
      isProd: keys.ENV === 'prod',
      isStaging: keys.ENV === 'stg',
    }

    this.eventsContext = {}
    this.userData = {}
    this.mapTrackEventIntegrations = {}
  }

  /**
   * @returns {Object of Functions} - functions are the integration specific implementations
   */
  getIntegrationsMap() {
    return {
      ga: (eventName, eventData) => {
        this.logEvents('Google Analytics', eventName, eventData)
        if (this.Ga) {
          this.Ga('send', {
            eventData,
            eventName,
          })
        }
      },
      fb: (eventName, eventData) => {
        this.logEvents('Facebook Pixel', eventName, eventData)
        if (this.Fb) {
          this.Fb('track', eventName, eventData)
        }
      },
      mp: (eventName, eventData) => {
        if (!this.envKeys.isProd) {
          MixpanelStorage.push({
            eventName,
            ...eventData,
          })
        }
        const data = { event: eventData, user: this.userData }
        this.logEvents('Mixpanel', eventName, data)
        if (this.Mixpanel) {
          this.Mixpanel.track(eventName, eventData)
        }
      },
      seg: (eventName, eventData) => {
        this.logEvents('Segment', eventName, eventData)
        if (this.Segment) {
          window.analytics.track(eventName, eventData)
        }
      },
    }
  }

  /**
   * Override the method in child adapter to configure integrations
   */
  initAndConfigureIntegrations() {
    this.mapTrackEventIntegrations = this.getIntegrationsMap()

    if (this.envKeys.isLocal) {
      return this
    }
    if (this.envKeys.SENTRY) {
      this.Sentry = stubFn
      // configure
    }
    if (this.envKeys.SEGMENT) {
      this.Segment = stubFn
      // configure
    }
    if (this.envKeys.MIXPANEL) {
      this.Mixpanel = stubFn
      // configure
    }
    if (this.envKeys.GTM) {
      this.Ga = stubFn
      // configure
    }
    if (this.envKeys.FB_PIXEL && this.envKeys.FB_APP) {
      this.Fb = stubFn
      // configure
    }

    return this
  }

  /**
   * Method to log events that are triggered
   * @param {string} toolName - The tool which logs the event
   * @param  {...any} params - Array of parameters that needs to be logged
   */
  logEvents(toolName, ...params) {
    if (!this.envKeys.isProd) {
      console.groupCollapsed(`${toolName.toUpperCase()} EVENT`)
      params.forEach((param) => console.log(JSON.stringify(param, null, 2)))
      console.groupEnd()
    }
  }

  setEventsContext(data = {}) {
    this.eventsContext = {
      ...this.eventsContext,
      ...data,
    }

    return this
  }

  /**
   *
   * @param {String} eventName - name of the event
   * @param {Object} eventData - data surrounding the event
   * @param {Array} integrations - list of integrations that need to track the particular event
   */
  trackEvent(
    eventName = 'AnonymousUserEvent',
    eventData = {},
    integrations = ['fb', 'ga', 'mp', 'seg']
  ) {
    const eventContextAndData = {
      ...this.eventsContext,
      ...eventData,
    }

    integrations.forEach((integration) => {
      if (typeof this.mapTrackEventIntegrations[integration] === 'function') {
        // delayExecution(() =>
        this.mapTrackEventIntegrations[integration](eventName, eventContextAndData)
        // )
      } else {
        this.logEvents(
          'Tracking Failed',
          `Event: ${eventName}`,
          `Integration: ${integration}`,
          eventContextAndData
        )
      }
    })

    return this
  }

  /***************|| Event Tracking APIs ||*******************/

  analyticsSignIn(userData = {}) {
    this.userData = userData

    if (this.Ga) {
      this.Ga('config', this.envKeys.GTM, userData)
    }

    if (!this.envKeys.isProd && (userData.$phone || userData.sub)) {
      MixpanelStorage.push(
        {
          eventName: 'MixpanelIdentify',
          id: userData.sub || userData.$phone,
        },
        {
          eventName: 'MixpanelPeopleSet',
          userData,
        }
      )
    }

    if (this.Mixpanel) {
      if (userData.$phone || userData.sub) {
        this.Mixpanel.identify(userData.sub || userData.$phone)
        this.Mixpanel.people.set(userData)
      }
    }

    if (this.Fb) {
      this.Fb('setUserProperties', this.envKeys.FB_PIXEL, userData)
    }

    if (this.Segment) {
      window.analytics.identify(userData.user_id, {
        email: userData.email,
        first_name: userData.first_name,
        fr_user_id: userData.sub,
        last_name: userData.last_name,
        role: userData.role,
      })
    }

    return this
  }

  analyticsSignOut() {
    if (this.Mixpanel) {
      this.Mixpanel.reset()
    }
    this.userData = {}
    return this
  }

  analyticsSignUp(id) {
    this.logEvents('mixpanel', 'setAlias - Internal Event', { id })

    if (!this.envKeys.isProd) {
      MixpanelStorage.push({
        eventName: 'MixpanelAlias',
        id,
      })
    }

    if (this.Mixpanel) {
      this.Mixpanel.alias(id)
    }

    return this
  }

  /***************|| Error Reporting APIs ||*******************/

  trackError(error, priority = 'high') {
    this.logEvents('Sentry Error', error)

    if (!this.Sentry) return this

    if (error.code && error.message && error.name) {
      this.Sentry.captureEvent({ message: error.message, extra: error })
    } else if (priority === null) {
      this.Sentry.captureException(error)
    } else {
      this.Sentry.withScope((scope) => {
        scope.setTag('priority', priority)
        this.Sentry.captureException(error)
      })
    }

    return this
  }

  setErrorContext(errorInfo, priority = 'high') {
    if (typeof errorInfo === 'string') {
      errorInfo = { errorInfo }
    }
    this.logEvents('Sentry Context Set', errorInfo)

    if (!this.Sentry) return this

    this.Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      scope.setTag('priority', priority)
    })

    return this
  }

  setErrorUser(userInfo) {
    this.logEvents('Sentry User SignIn', userInfo)

    if (!this.Sentry) return this

    this.Sentry.configureScope((scope) => {
      scope.setUser(userInfo)
    })

    return this
  }

  /******************|| Setup Guide APIs ||*********************/

  addGuides(config) {
    const { blid: id, email } = config

    if (typeof window !== 'undefined' && window.pendo) {
      window.pendo.initialize({
        visitor: {
          email,
        },
        account: {
          id,
        },
      })
    }

    return this
  }
}

export default EventTracking
